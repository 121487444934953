import React, { useState, useContext, useEffect } from 'react';

import Modal from './Modal';
import helpers from '../helpers';
import LoadingSpinner from './LoadingSpinner';
import UserContext from '../UserContext';
import ModalContext from './ModalContext';
import api from '../api';

function isHMISType(type) {
    return type.includes("HMIS");
}

function EntryRow(props) {
    const [isEditing, setIsEditing] = useState(props.mode === "working");
    const [day, setDay] = useState(props.entry.day);
    const [cacn, setCACN] = useState(props.entry.cacn);
    const [requestor, setRequestor] = useState(props.entry.requestor);
    const [hours, setHours] = useState(props.entry.hours);
    const [adjustedHours, setAdjustedHours] = useState(props.entry.adjustedHours);
    const [description, setDescription] = useState(props.entry.description);
    const [type, setType] = useState(props.entry.type);
    const [workOrder, setWorkOrder] = useState(props.entry.workOrder);
    const modaling = useContext(ModalContext);
    const userContext = useContext(UserContext);

    const saveEntry = (event) => {
        event.preventDefault();
        let newEntry = props.entry;
        newEntry.day = day;
        newEntry.cacn = cacn;
        newEntry.requestor = requestor;
        newEntry.hours = parseFloat(hours);
        newEntry.adjustedHours = parseFloat(adjustedHours);
        newEntry.description = description;
        newEntry.type = type;
        newEntry.workOrder = workOrder;
        if(props.saveEntry(props.index, newEntry)) {
            if(props.mode === "working") {
                props.closeWorkingEntry(event);
            }
            else {
                toggleEditing(event);
            }
        }
    }

    const toggleEditing = (event) => {
        event.preventDefault();
        setIsEditing(!isEditing);
    }

    const confirmDelete = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnColor: 'danger',
                btnInner: 'Yes, Delete',
                func: (event) => {
                    event.preventDefault();
                    props.deleteEntry(props.index);
                    modaling.setModal(null);
                }
            },
            {
                btnColor: 'secondary',
                btnInner: 'Cancel',
                func: (event) => {
                    event.preventDefault();
                    modaling.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[1].func}>
            <h3>
                Are you sure?
            </h3>
            <p>
                Are you sure you want to delete this entry?
            </p>
        </Modal>
        modaling.setModal(modal);
    }

    const getWorkOrdersInLocalStorage = () => {
        const workOrdersJson = localStorage.getItem('workOrders');
        if(workOrdersJson) {
            const workOrders = JSON.parse(workOrdersJson);
            return workOrders;
        }
        else {
            console.warn('There are no Work Orders in LocalStorage.');
            return [];
        }
    }

    const getWorkOrderInLocalStorageByNumber = (workOrderNumber) => {
        const workOrdersJson = localStorage.getItem('workOrders');
        if(workOrdersJson) {
            const workOrders = JSON.parse(workOrdersJson);
            for(const workOrder of workOrders) {
                if(workOrder.workOrderNumber === workOrderNumber) {
                    return workOrder;
                }
            }
            return null;
        }
        else {
            return null;
        }
    }

    const forUsersPositionTitleInWorkOrder = (workOrder) => {
        let email = props.forUser ? props.forUser.email : userContext.loggedInUser.email;
        for(const position of workOrder.positions) {
            for(const user of position.users) {
                if(user.email === email) {
                    return position.positionTitle;
                }
            }
        }
        return '';
    }

    const findUserInWorkOrder = (userEmail, workOrder) => {
        for(const position of workOrder.positions) {
            for(const user of position.users) {
                if(user.email === userEmail) {
                    return user;
                }
            }
        }
        return null;
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch(name) {
            case 'day':
                setDay(value);
                break;
            case 'cacn':
                setCACN(value);
                break;
            case 'requestor':
                setRequestor(value);
                break;
            case 'hours':
                setHours(value);
                setAdjustedHours(value);
                break;
            case 'adjustedHours':
                setAdjustedHours(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'type':
                if(helpers.isSiteFormsUser(props.forUser) || !isHMISType(value)) {
                    setCACN("N/A");
                    setRequestor("N/A");
                    setWorkOrder({number: "N/A", positionTitle: "N/A"});
                }
                else {
                    setCACN("");
                    setRequestor("");
                }
                setType(value);
                break;
            case 'workOrder':
                if(value === 'N/A') {
                    setWorkOrder({number: 'N/A', positionTitle: 'N/A'});
                }
                else {
                    const workOrder = getWorkOrderInLocalStorageByNumber(value);
                    const positionTitle = forUsersPositionTitleInWorkOrder(workOrder);
                    const position = workOrder.positions.find(pos => pos.positionTitle === positionTitle);
                    setWorkOrder(workOrder ? {number: workOrder.workOrderNumber, positionTitle: positionTitle, isOverThreshold: position.isOverThreshold, hoursSum: position.hoursSum, hoursBudget: position.hoursBudget} : {number: '', positionTitle: ''});
                }
            default:
                break;
        }
    }

    const applicableWorkOrders = () => {
        return getWorkOrdersInLocalStorage().filter((workOrder) => {
            const activeRange = helpers.extrapolateDays(workOrder.startDate, workOrder.endDate);
            const foundUser = findUserInWorkOrder(props.forUser ? props.forUser.email : userContext.loggedInUser.email, workOrder);
            return foundUser && activeRange.includes(props.entry.day);
        });
    }

    const date = helpers.dateFromISOFragment(day);

    return (
        <tr>
            {props.showUser ? <td>{props.entry.user.first} {props.entry.user.last}</td> : null}
            {props.showDay ? 
                <>
                {isEditing ? 
                    <td><input className="form-control" type="date" name="day" value={day} onChange={handleChange}/></td>
                : 
                    <td>{helpers.dayOfWeekStr(date.getDay())}, {helpers.monthStr(date.getMonth())} {date.getDate()}</td>
                }
                </>
            : null}
            <td>{isEditing ? <select className="form-select" name="type" value={type} onChange={handleChange}>
                <option value="NOT SET">--SELECT ONE--</option>
                {!helpers.isSiteFormsUser(props.forUser) ? <option value="HMIS">HMIS</option> : null}
                <option value="CBIT">CBIT</option>
                <option value="Sick">Sick</option>
                <option value="Vacation">Vacation</option>
                {!helpers.isSiteFormsUser(props.forUser) ? <option value="Holiday (HMIS)">Holiday (HMIS)</option> : null}
                <option value="Holiday (CBIT)">Holiday (CBIT)</option>
                <option value="OT">OT</option>
                <option value="OCW">OCW</option>
            </select> : props.entry.type}</td>
            {!helpers.isSiteFormsUser(props.forUser) ? <td>{isEditing ? <><select className="form-select" name="workOrder" value={workOrder.number} onChange={handleChange} disabled={!isHMISType(type)}>
                <option value='N/A'>N/A</option>
                {applicableWorkOrders().map((workOrder, i) => <option key={i} value={workOrder.workOrderNumber}>{workOrder.workOrderNumber} ({forUsersPositionTitleInWorkOrder(workOrder)})</option>)}
            </select></> : props.entry.workOrder.number === "N/A" ? "N/A" : `${props.entry.workOrder.number} (${props.entry.workOrder.positionTitle})`}</td>:null}
            {!helpers.isSiteFormsUser(props.forUser) ? <td>{isEditing ? <input className="form-control" type="text" name="cacn" value={cacn} onChange={handleChange} maxLength={6} disabled={!isHMISType(type)}/> : props.entry.cacn}</td> : null}
            {!helpers.isSiteFormsUser(props.forUser) ? <td>{isEditing ? <input className="form-control" type="text" name="requestor" value={requestor} onChange={handleChange} disabled={!isHMISType(type)}/> : props.entry.requestor}</td> : null}
            <td className="description">{isEditing ? <textarea className="form-control" name="description" value={description} onChange={handleChange}/> : props.entry.description}</td>
            <td>{isEditing ? <input className="form-control" type="number" name="hours" value={hours} onChange={handleChange} min="0" max="24" step="0.25"/> : parseFloat(props.entry.hours).toFixed(2)}</td>
            {userContext.loggedInUser.roles.includes('admin') ? <td>{isEditing ? <input className="form-control" type="number" name="adjustedHours" value={adjustedHours} onChange={handleChange} min="0" max="24" step="0.25"/> : parseFloat(props.entry.adjustedHours).toFixed(2)}</td> : null}
            {props.readonly ? null : <td>
                {isEditing ?  
                    <>
                    <button className="btn btn-success mb-2" onClick={saveEntry} disabled={type === "NOT SET"}>
                        <i className="fas fa-bookmark"/>
                        &nbsp; Submit
                    </button>
                    <button className="btn btn-secondary" onClick={props.mode === "working" ? props.closeWorkingEntry : toggleEditing}>
                        <i className="fas fa-times"/>
                        &nbsp; Cancel
                    </button>
                    </>
                :
                    <>
                    <button className="btn btn-secondary me-2" onClick={toggleEditing}>
                        <i className="fas fa-pen"/>
                        &nbsp; Edit
                    </button>
                    <button className="btn btn-danger" onClick={confirmDelete}>
                        <i className="fas fa-minus"/>
                        &nbsp; Delete
                    </button>
                    </>
                }
            </td> }
        </tr>
    )
}

function EntriesTable(props) {
    const [showWorkingEntry, setShowWorkingEntry] = useState(false);
    const [loading, setLoading] = useState(false);
    const userContext = useContext(UserContext);
    const forUser = props.forUser ? props.forUser : userContext.loggedInUser;

    useEffect(() => {
        if(props.readonly) {
            setShowWorkingEntry(false);
        }
    }, [props.readonly])

    const deleteEntry = async(entryIndex) => {
        let newEntries = [];
        for(let i = 0; i < props.entries.length; i++) {
            let entry = props.entries[i];
            if(i === entryIndex) {
                setLoading(true);
                try {
                    await api.deleteEntryByID(entry._id);
                    setLoading(false);
                }
                catch(err) {
                    if(err.name !== "AbortError") {
                        console.error(err);
                        alert("ERROR: Failed to delete TimecardEntry. Please refresh and try again. Contact the website administrator if the problem persists.");
                    }
                }
            }
            else {
                newEntries.push(entry);
            }
        }
        if(props.updateEntries)
            props.updateEntries(newEntries);
        props.setEntries(newEntries);
    }

    const saveEntry = async(entryIndex, newEntry) => {
        let newEntries = [];
        setLoading(true);
        if(entryIndex === props.entries.length) {
            for(const entry of props.entries) {
                newEntries.push(entry);
            }

            try {
                newEntries.push(await api.createEntry(newEntry));
            }
            catch(err) {
                if(err.name !== "AbortError") {
                    console.error(err);
                    alert("ERROR: Failed to create TimecardEntry. Please refresh and try again. Contact the website administrator if the problem persists.");
                }
                return false;
            }
        }
        else {
            for(let i = 0; i < props.entries.length; i++) {
                let entry = props.entries[i];
                if(i === entryIndex) {
                    try {
                        newEntries.push(await api.updateEntry(entry._id, newEntry));
                    }
                    catch(err) {
                        if(err.status === 422) {
                            for(const error in err.json) {
                                alert(`Error saving the entry: ${err.json[error].message}`);
                            }
                        }
                        else if(err.name !== "AbortError") {
                            console.error(err);
                            alert("ERROR: Failed to update TimecardEntry. Please ")
                        }
                        return false;
                    }
                }
                else {
                    newEntries.push(entry);
                }
            }
        }
        setLoading(false);
        if(props.updateEntries)
            props.updateEntries(newEntries);
        props.setEntries(newEntries);
        return true;
    }

    const startAddEntry = (event) => {
        event.preventDefault();
        setShowWorkingEntry(true);
    }

    const closeWorkingEntry = (event) => {
        event.preventDefault();
        setShowWorkingEntry(false);
    }

    const totalHours = (adjusted) => {
        let total = 0;
        for(const entry of props.entries) {
            total += adjusted ? entry.adjustedHours : entry.hours;
        }
        return total;
    }

    const totalHmisHours = (adjusted) => {
        let total = 0;
        for(const entry of props.entries) {
            if(entry.type === "HMIS" || entry.type === "Holiday (HMIS)") {
                total += adjusted ? entry.adjustedHours : entry.hours;
            }
        }
        return total;
    }

    let renderedEntries = [];
    for(let i = 0; i < props.entries.length; i++) {
        renderedEntries.push(
            <EntryRow forUser={forUser} entry={props.entries[i]} index={i} deleteEntry={deleteEntry} saveEntry={saveEntry} showDay={props.showDay} readonly={props.readonly} showUser={props.showUser}/>
        )
    }

    let workingEntry = null;
    if((props.day || props.forUser) && forUser) {
        workingEntry = {
            day: props.day ? props.day : "",
            cacn: "",
            hours: 0.0,
            adjustedHours: 0.0,
            description: "",
            requestor: "",
            type: "NOT SET",
            locked: false,
            workOrder: {
                number: 'N/A',
                positionTitle: 'N/A'
            },
            user: {
                first: forUser.first,
                last: forUser.last,
                email: forUser.email
            }
        }
    }

    return(
        <>
        {props.entries.length > 0 || showWorkingEntry && !loading?
        <table className="table table-striped table-bordered table-layout-fixed w-100">
            <thead>
                <tr>
                    {props.showUser ? <th scope="col">User</th> : null }
                    {props.showDay ? <th scope="col">Date</th> : null }
                    <th scope="col">Type</th>
                    {!helpers.isSiteFormsUser(forUser) ? <th scope="col">Work Order</th> : null}
                    {!helpers.isSiteFormsUser(forUser) ? <th scope="col">CACN</th> : null}
                    {!helpers.isSiteFormsUser(forUser) ? <th scope="col">Requestor</th> : null}
                    <th scope="col">Description</th>
                    <th scope="col" style={{width: '9%'}}>Hours</th>
                    {userContext.loggedInUser.roles.includes('admin') ? <th scope="col">Adjusted Hours</th> : null}
                    {props.readonly ? null : <th scope="col">Actions</th> }
                </tr>
            </thead>
            <tbody>
                {renderedEntries}
                {props.showTotal && !showWorkingEntry && totalHmisHours() > 0 ? <tr><td className="text-end" colSpan={(props.showUser ? 7 : 6) - !props.showDay - (helpers.isSiteFormsUser(forUser) ? 3 : 0)}><strong>Total HMIS Hours:</strong></td><td style={{borderTop: '2px solid black'}}><strong>{totalHmisHours(false).toFixed(2)}</strong></td>{userContext.loggedInUser.roles.includes('admin') ? <td style={{borderTop: '2px solid black'}}><strong>{totalHmisHours(true).toFixed(2)}</strong></td> : null}{props.readonly ? null : <td></td>}</tr> : null}
                {props.showTotal && !showWorkingEntry ? <tr><td className="text-end" colSpan={(props.showUser ? 7 : 6) - !props.showDay - (helpers.isSiteFormsUser(forUser) ? 3 : 0)}><strong>Total Hours:</strong></td><td style={{borderTop: '2px solid black'}}><strong>{totalHours(false).toFixed(2)}</strong></td>{userContext.loggedInUser.roles.includes('admin') ? <td style={{borderTop: '2px solid black'}}><strong>{totalHours(true).toFixed(2)}</strong></td> : null}{props.readonly ? null : <td></td>}</tr> : null}
                {showWorkingEntry && (props.day || props.forUser) && forUser ? 
                    <EntryRow forUser={forUser} entry={workingEntry} index={props.entries.length} mode="working" closeWorkingEntry={closeWorkingEntry} deleteEntry={deleteEntry} saveEntry={saveEntry} showDay={props.showDay} readonly={props.readonly} showUser={props.showUser}/>
                :null}
            </tbody>
        </table>
        :null}
        {!showWorkingEntry && (props.day || props.forUser) && forUser && !props.readonly ? 
            <button className="btn btn-success w-fit" onClick={startAddEntry}>
                <i className="fas fa-plus"></i>
                &nbsp; Add Entry
            </button>
        :null}
        {loading ? 
            <LoadingSpinner size="50px"/>
        :null}
        </>
    )
}

export default EntriesTable;